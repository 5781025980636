<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "StrukturPerusahaan",

  metaInfo: { title: "Struktur Perusahaan" },

  extends: View,

  mixins: [
    LoadSections([
      // "hero-alt",
      "commissioner",
      // 'com-syariah',
      "boards",
      // 'teams',
      "info",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
